<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-button
            variant="success"
            @click="formAnnouncement"
          >
            Add Announcement
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="data.length === 0">
        <p class="italiceze mx-auto mb-0 ">
          No announcement to display
        </p>
      </b-row>
      <b-row class="mt-4">
        <b-col
          v-for="(
            { title, description, photos, files, active, created_at, id }, key
          ) in data"
          :key="key"
          md="6"
          cols="12"
          class="mb-2"
        >
          <b-card
            :title="title"
            :img-src="computedImgSrc(photos)"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2 shadow flex-fill"
          >
            <b-card-text class="min-h-100">
              {{ description }}
            </b-card-text>

            <div class="mb-4 d-flex flex-column justify-content-center align-items-start">
              <a
                v-for="(file, fileKey) in files "
                :key="fileKey"
                target="_blank"
                :href="file.path"
                class="py-2"
              >
                Click here to download
              </a>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p>Status: {{ active == 1 ? "active" : "inactive" }}</p>
              <p>Created at: {{ dateFormatter(created_at) }}</p>
            </div>
            <div class="d-flex justify-content-start mt-2 align-items-center">
              <b-button
                variant="success"
                @click="viewUpdateAnnouncement(data[key])"
              >
                Edit Announcement
              </b-button>
              <b-button
                class="ml-2"
                variant="primary"
                @click="viewUploadedPhoto(data[key], id)"
              >
                Edit Uploaded photo
              </b-button>
            </div>
          </b-card>
          <!-- <div class="mycard shadow">
            <div class="mycard__container">
              <div class="mycard__container--image">
                <img
                  :src="`${photos[0]}`"
                  alt="image data"
                >
              </div>
              <div class="mycard__container--description shadow">
                <div class="message">
                  <h4>{{ title }}</h4>
                  <p>

                    {{ description }}
                  </p>

                  <div class="mb-4 d-flex flex-column justify-content-center align-items-start">
                    <a
                      v-for="(file, fileKey) in files "
                      :key="fileKey"
                      target="_blank"
                      :href="file.path"
                      class="py-2"
                    >
                      link files
                      <a /></a></div>
                </div>

              </div>
            </div>
          </div>-->
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-announcement-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      class="mb-5"
      size="lg"
      @ok="saveRecord"
      @show="(event) => {}"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="title"
                vid="title"
              >
                <b-form-group>
                  <label for="title">Title</label>
                  <b-input
                    id="title"
                    v-model="announcement.title"
                    type="text"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    placeholder="enter title"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="title"
                vid="title"
              >
                <b-form-group>
                  <label for="comment">Enter description: </label>
                  <textarea
                    id="comment"
                    v-model="announcement.description"
                    name="comment"
                    rows="5"
                    placeholder="Enter description"
                    class="form-control"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="for_all"
                vid="for_all"
                rules="required"
              >
                <b-form-group>
                  <label for="for_all">For All ?</label>
                  <v-select
                    id="for_all"
                    v-model="selectedForAll"
                    :options="forAll"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="Select Fol all"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row v-if="!seeCluster">
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="cluster"
                vid="cluster"
                rules="required"
              >
                <b-form-group>
                  <label for="cluster">Cluster</label>
                  <v-select
                    id="cluster"
                    v-model="selectedCluster"
                    :options="clusters"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="Select Cluster"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>

              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row
            v-for="({ path, id }, key) in announcement.files"
            :key="key"
          >
            <b-col
              sm="9"
              lg="9"
              md="9"
            >
              <ValidationProvider
                name="files"
                vid="files"
              >
                <b-form-group>
                  <label
                    v-if="key === 0"
                    for="files"
                  >Enter Url Files: </label>
                  <b-input
                    id="files"
                    v-model="announcement.files[key].path"
                    name="files[]"
                    autocomplete="off"
                    rows="5"
                    placeholder="Enter files"
                    class="form-control"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              sm="3"
              lg="3"
              md="3"
              :class="`d-flex justify-content-center align-items-${
                key === 0 ? 'center mt-2' : 'start'
              } `"
            >
              <b-button
                v-if="announcement.files.length > 1"
                variant="danger"
                @click="removeFile(key, id)"
              >
                <i class="ri-delete-bin-6-line pr-0" />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              lg="12"
              md="12"
              class="mb-2"
            >
              <b-button
                variant="success"
                @click="addMoreFile"
              >
                <i class="ri-add-line" /> Add more file
              </b-button>
            </b-col>
          </b-row>

          <b-row v-if="!editMode">
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="photos"
                vid="photos"
                rules="required"
              >
                <b-form-group>
                  <label for="photos">Attach Image: </label>
                  <b-form-file
                    v-model="announcement.photos"
                    multiple
                    class="form-control"
                  >
                    <template
                      slot="file-name"
                      slot-scope="{ names }"
                    >
                      <b-badge variant="dark">
                        {{ names[0] }}
                      </b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row
            v-if="editMode"
            class="ml-1"
          >
            <b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                v-model="announcement.active"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                Active ?
              </b-form-checkbox>
            </b-form-group>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ editMode ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-uploaded-form"
      scrollable
      no-close-on-backdrop
      title="Uploaded File"
      class="mb-5"
      size="lg"
      @cancel="cancelModal"
      @ok="cancelModal"
    >
      <ul class="pl-0">
        <li
          v-for="(photo, key) in selectedPhotos"
          :key="key"
          class="d-flex justify-content-between align-items-center"
        >
          <span
            style="font-size: 1.6rem; color: #06c270; margin-right: 1rem"
          >{{ key + 1 }}.
          </span>
          <a
            :href="photo.path"
            target="_blank"
            download
            style="
              font-size: 1.6rem;
              text-decoration: underline;
              margin-right: auto;
            "
          >
            {{ photo.name }}
          </a>
          <b-button
            variant="danger"
            @click="removePhotoConfirm(photo)"
          >
            <i class="ri-delete-bin-line pr-0" />
          </b-button>
        </li>
      </ul>

      <ValidationObserver>
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col
              cols="12"
              md="9"
            >
              <ValidationProvider
                #default="{ errors }"
                name="uploadPhoto"
                vid="uploadPhoto"
                rules="required"
              >
                <b-form-group>
                  <label for="uploadPhoto">Attach Image: </label>
                  <b-form-file
                    v-model="uploadedPhoto"
                    required
                    multiple
                    name="uploadPhoto"
                    vid="uploadPhoto"
                    class="form-control"
                  >
                    <template
                      slot="file-name"
                      slot-scope="{ names }"
                    >
                      <b-badge variant="dark">
                        {{ names[0] }}
                      </b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex justify-content-center align-items-center"
            >
              <b-button
                v-if="uploadedPhoto.length > 0"
                variant="success"
                class="mt-2"
                type="submit"
                @click="newPhotoUploadedConfirm"
              >
                Upload Image <i class="ri-upload-2-fill pr-0" />
              </b-button>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AnnouncementService, AxiosError, ListService } from '@/services'

import formatter from '@/mixins/formatter'

export default {
  name: 'MarketingOfficerDashboard',

  middleware: ['auth', 'marketing-officer'],

  metaInfo () {
    return {
      files: 'Annoucement'
    }
  },
  mixins: [formatter],
  data () {
    return {
      modalTitle: 'Add Announcement',
      editMode: false,
      image: Image,
      data: [],
      clusters: [],
      seeCluster: 1,
      forAll: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }],
      selectedCluster: [],
      selectedForAll: [],
      announcement: {
        title: '',
        description: '',
        cluster: 0,
        for_all: 1,
        files: [
          {
            id: 0,
            path: ''
          }
        ],
        photos: [],
        active: 1,
        id: 0
      },
      selectedPhotos: [],
      uploadedPhoto: [],
      announcement_id: '',
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: []
      }
    }
  },

  watch: {
    'selectedCluster' ({ value }) {
      this.announcement = { ...this.announcement, cluster: value }
    },
    'selectedForAll' ({ value }) {
      this.seeCluster = value === 1 ? 1 : 0
      this.announcement = { ...this.announcement, for_all: value }
    }
  },

  mounted () {
    core.index()
    this.myProvider(this.tableSettings)
    this.getClusterList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        await AnnouncementService.get(
          `page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`
        ).then(data => {
          this.tableSettings.totalRows = data.data.total_rows
          this.data = data.data.items
        })
      } catch {
        return []
      }
    },

    formAnnouncement () {
      this.editMode = false
      this.announcement = {
        title: '',
        description: '',
        for_all: 1,
        cluster: 0,
        files: [
          {
            id: 0,
            path: ''
          }
        ],
        photos: [],
        active: 1,
        id: 0
      }
      this.modalTitle = 'Add Announcement'
      this.$bvModal.show('modal-announcement-form')
    },

    async addMoreFile () {
      const files = [...this.announcement.files, { path: '', id: 0 }]
      this.announcement = { ...this.announcement, files: files }
    },

    async removeFile (index, id) {
      const { files } = this.announcement
      if (id === 0) {
        files.splice(index, 1)
      } else {
        this.$swal.fire({
          title: 'Are you sure want to remove this file ?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Remove',
          cancelButtonColor: '#FF2929',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.delteFile(id)
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
      }
    },

    async delteFile (id) {
      await AnnouncementService.deleteFile(id).then(res => {
        this.$swal
          .fire({
            icon: 'success',
            title: 'Success',
            text: res.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
          .then(async () => {
            await this.myProvider(this.tableSettings)
            await this.refreshUpdatingFile()
          })
      })
    },

    async saveRecord (event) {
      event.preventDefault()

      const titleMessage =
        this.editMode === true
          ? 'Are you sure want to update this announcement?'
          : 'Are you sure want to add this announcement?'

      this.$swal.fire({
        title: titleMessage,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#06C270',
        confirmButtonText: this.editMode === true ? 'Update' : 'Save',
        cancelButtonColor: '#FF2929',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.editMode ? this.onUpdateAnnouncement() : this.onSubmitAnnouncement()
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },
    async onUpdateAnnouncement () {
      await AnnouncementService.updateAnnouncement(this.announcement).then(res => {
        this.$swal
          .fire({
            icon: 'success',
            title: 'Success',
            text: res.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
          .then(() => {
            this.myProvider(this.tableSettings)
            this.$bvModal.hide('modal-announcement-form')
          })
      })
    },

    async onSubmitAnnouncement () {
      const formBody = new FormData()
      const { title, description, photos, files, active, for_all, cluster } = this.announcement

      let counterImage = 0
      photos.forEach(file => {
        formBody.append(`image[${counterImage}]`, file)
        counterImage++
      })

      formBody.append('title', title)
      formBody.append('description', description)
      formBody.append('active', active)
      formBody.append('for_all', for_all)
      formBody.append('cluster', cluster)
      formBody.append('files', JSON.stringify(files))

      await AnnouncementService.addAnnouncement(formBody)
        .then(res => {
          this.$bvModal.hide('modal-announcement-form')
          this.$swal
            .fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              this.myProvider(this.tableSettings)
            })
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk(
                error.message?.['image.0']?.[0] ||
                  'Error in creating announcement, please contact the IT department.',
                {
                  title: 'Validation Error',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'danger'
                }
              )
            }
          }
        })
    },

    viewUpdateAnnouncement (data) {
      this.editMode = true

      this.announcement = {
        id: data.id,
        active: data.active,
        title: data.title,
        description: data.description,
        files: data.files,
        for_all: data.for_all,
        cluster: data.cluster_id
      }
      this.selectedForAll = this.forAll.filter(el => el.value === data.for_all)
      this.selectedCluster = this.clusters.filter(el => el.value === data.cluster_id)
      this.$bvModal.show('modal-announcement-form')
    },

    viewUploadedPhoto (data, id) {
      this.$bvModal.show('modal-uploaded-form')
      this.announcement_id = id
      this.selectedPhotos = data.photos
    },

    computedImgSrc (value) {
      return value?.[0]?.path || ''
    },

    async removePhotoConfirm (value) {
      this.$swal.fire({
        title: 'Are you sure want to remove this photo?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Remove',
        cancelButtonColor: '#FF2929',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.removePhoto(value)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async removePhoto (value) {
      return await AnnouncementService.removePhoto(value).then(res => {
        this.isBusy = false
        this.$swal
          .fire({
            icon: 'success',
            title: 'Success',
            text: res.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
          .then(async () => {
            await this.myProvider(this.tableSettings)
            await this.refreshUpdatingPhoto()
          })
      })
    },

    async newPhotoUploadedConfirm (e) {
      e.preventDefault()
      const uploadedPhoto = this.uploadedPhoto
      if (uploadedPhoto.length > 0) {
        this.$swal.fire({
          title: 'Are you sure want to upload this photo?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Upload',
          cancelButtonColor: '#FF2929',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.newPhotoUploaded(uploadedPhoto)
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
      }
    },
    async newPhotoUploaded (uploadedPhoto) {
      const formBody = new FormData()
      let counterImage = 0
      uploadedPhoto.forEach(file => {
        formBody.append(`image[${counterImage}]`, file)
        counterImage++
      })
      formBody.append('announcement_id', this.announcement_id)

      await AnnouncementService.uploadPhoto(formBody)
        .then(res => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              this.myProvider(this.tableSettings)
              this.$bvModal.hide('modal-uploaded-form')
              this.uploadedPhoto = []
            })
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk(
                error.message?.['image.0']?.[0] ||
                  'Error in creating announcement, please contact the IT department.',
                {
                  title: 'Validation Error',
                  size: 'md',
                  buttonSize: 'md',
                  okVariant: 'danger'
                }
              )
            }
          }
        })
    },
    cancelModal () {
      this.uploadedPhoto = []
    },

    async refreshUpdatingFile () {
      const announcement = this.data.filter(el => el.id === this.announcement.id)
      this.announcement = {
        title: announcement[0].title,
        description: announcement[0].description,
        files: announcement[0].files,
        active: announcement[0].active,
        id: announcement[0].id
      }
    },

    async refreshUpdatingPhoto () {
      const announcement = this.data.filter(el => el.id === this.announcement_id)
      this.selectedPhotos = announcement[0].photos
    },

    async getClusterList () {
      await ListService.getClusters().then(({ data }) => {
        this.clusters = data.map(({ id, cluster_name }) => {
          return { value: id, label: cluster_name }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.card-img-top {
  cursor: pointer;
}
.mycard {
  width: 100%;
  height: auto;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  &__container {
    display: grid;
    grid-template-rows: max-content max-content;
    position: relative;

    &--image {
      width: 100%;

      img {
        width: 100%;
        height: auto;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        cursor: pointer;
      }
    }
    &--description {
      position: absolute;
      background: white;
      bottom: 0;
      right: 0;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      height: 60%;
      width: 100%;

      .message {
        padding: 2rem;
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}
</style>
